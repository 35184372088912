import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../authentication.service';
import { debounceTime, delay, distinctUntilChanged, filter, map, takeUntil, tap } from 'rxjs/operators';
import { Observable, ReplaySubject, Subject, of } from 'rxjs';
import { selectEffectiveTheme } from '../../core/settings/settings.selectors';
import { AuthenticationActions } from '../action-types';
import { selectAuthenticationTheme, selectorCompanyName, selectorUserScopes } from '../authentication.selectors';
import { TranslateService } from '@ngx-translate/core';
import { LandlordService } from 'app/landlords/data/landlord.service';
import { TenantService } from 'app/tenants/data/tenant.service';
import { NotificationService } from 'app/shared/notification.service';


@Component({
  selector: 'app-tenant-sign-up',
  templateUrl: './tenant-sign-up.component.html',
  styleUrls: ['./tenant-sign-up.component.scss']
})
export class TenantSignUpComponent implements OnInit {


  loginForm: FormGroup;
  returnUrl: string;
  loginError = '';
  loader = false;
  token$: any;
  public searching = false;
  theme$: Observable<string>;
  landlordsFiltered$: Observable<any>;
  public landlordServerSideFilteringCtrl: FormControl = new FormControl();
  protected _onDestroy = new Subject<void>();
  formErrors: any;
  form: FormGroup;
  loginScopes: any;
  signUP:boolean =true;
  companyName: string;
  public filteredServerSideLandlords: ReplaySubject<any> = new ReplaySubject<any>(1);
  
  constructor(private fb: FormBuilder,
    private translateService: TranslateService,
    private landlordService: LandlordService,
    private tenantService: TenantService,
    private notification: NotificationService,
    private store: Store, private route: ActivatedRoute,
    private router: Router, private authenticationService: AuthenticationService) {
    this.loginForm = fb.group({
      first_name: ['',Validators.required],
      last_name: ['',Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.pattern(
        /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*#?&^_-]).{8,}/
      ),]],
      password_confirmation: ['', [Validators.required, Validators.pattern(
        /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*#?&^_-]).{8,}/
      )]],
      phone: ['', Validators.required],
      // landlord_id: ['', [Validators.required,
      // Validators.minLength(2)]],
      live_status: ['landlord']
    });
    //   this.store.pipe(select(selectorCompanyName)).subscribe(name => this.companyName = name);
  }

  ngOnInit() {
    this.theme$ = this.store.pipe(select(selectAuthenticationTheme));

    this.landlordServerSideFilteringCtrl.valueChanges
      .pipe(
        filter(search => !!search),
        tap(() => this.searching = true),
        takeUntil(this._onDestroy),
        debounceTime(2000),
        distinctUntilChanged(),
        map(search => {
          search = search.toLowerCase();
          this.landlordsFiltered$ = this.landlordService.search(search);
        }),
        delay(500)
      )
      .subscribe(filteredLandlords => {
        this.searching = false;
        this.filteredServerSideLandlords.next(filteredLandlords);
      },
        error => {
          this.searching = false;
        });
  }

  public onSaveComplete(): void {
    this.loader = false;
    this.router.navigate(['/login']);
  }

  public change(data){
    console.log(data);
    if(data == "landlord"){
      this.signUP = true;
      this.loginForm.removeControl('landlord_id');
    }else{
      this.signUP = false;
      this.loginForm.addControl('landlord_id', new FormControl('', Validators.required));
    }
  }
  
  signUp() {
    const tenantFields = {
      ...this.loginForm.value,
    };
    this.loader = true;
    if(!this.signUP){
      this.tenantService.tenantCreate(tenantFields)
      .subscribe((res) => {
        this.loader = false;
        this.notification.showNotification('success',
          this.translateService.instant('tenants.notification.tenant_created'));
        this.onSaveComplete();
      },
        (error) => {
          this.loader = false;
          if (error.lead === 0) {
            this.notification.showNotification('danger', this.translateService.instant('connection_error'));
            return;
          }
          this.formErrors = error;

          if (this.formErrors) {
              for (const prop in this.formErrors) {
                  if (this.form) {
                      this.form.controls[prop]?.markAsTouched();
                      this.form.controls[prop].setErrors({incorrect: true});
                  }
              }
          }
        });
    }else{
      this.tenantService.landlordsCreate(tenantFields)
      .subscribe((res) => {
        this.loader = false;
        this.notification.showNotification('success', this.translateService.instant('landlords.create.success'));
        this.onSaveComplete();
      },
        (error) => {
          this.loader = false;
          if (error.lead === 0) {
            this.notification.showNotification('danger', this.translateService.instant('connection_error'));
            return;
          }
          this.formErrors = error;

          if (this.formErrors) {
              for (const prop in this.formErrors) {
                  if (this.form) {
                      this.form.controls[prop]?.markAsTouched();
                      this.form.controls[prop].setErrors({incorrect: true});
                  }
              }
          }
        });
    }
    
  }

}
